import React from 'react'
import  {Fba}from '../Container/FbaCalculator/Fba'
import ContactForm from '../Components/contactForm';
import Footer from '../Components/Footer';

function FbaCalculator() {
  return (
    <div>
        <Fba/>
        <ContactForm/>
        <Footer/>
        
    </div>
  )
}
export default FbaCalculator;
