import { Container } from "@mui/material";
import React, { Dispatch, useEffect, useState } from "react";
import useStyles from "../News/style";
import { ArrowBackSharp } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../../Components/Footer";
import { useDispatch, useSelector } from "react-redux";
import { fetchBlogs } from "../../actions/posts";

interface BlogApiData {
  id: number | null;
  attributes: {
    blog_img: string;
    blog_url: string;
    createdAt: string;
    publishedAt: string;
    title: string;
    updatedAt: string;
    whole_bloc: string;
  };
}

function BlogContent() {
  const classes = useStyles();
  const navigate = useNavigate();
  // const item = JSON.parse(localStorage.getItem("blogData") || "{}");
  const [item, setItem] = useState<any>();

  const { id } = useParams();

  const response = useSelector((state: any) => state?.posts?.fetchBlogs?.data);

  const dispatch = useDispatch() as any;
  useEffect(() => {
    dispatch(fetchBlogs()).then((res: any) => {
      // console.log(res.data,'qwerqewr');
      
      let currentItems = res.data.filter( (item: any) => item.id == id)
      setItem(currentItems[0])
    })
    
  }, [dispatch]);

  console.log('item',item);

  // let currentItems = response?.filter((item: any) => item.id === id);
  // setItem(currentItems[0])

  // useEffect(() => {
  //   if (currentItems) {
  //     setItem(currentItems[0])
  //   }
  // }, [currentItems]);


  return (
    <>
      <Container
        className="container margin-nav"
        style={{ backgroundColor: "#FFFCFC" }}
      >
        <div
          className={`${classes.conatainer} ${classes.relative}`}
          style={{ padding: "20px 0" }}
        >
          <ArrowBackSharp
            className={classes.arr}
            onClick={() => navigate(-1)}
          />
          <div style={{ position: "relative", zIndex: 2 }}>
            <img
              src={item?.attributes?.blog_img}
              alt="img"
              width="100%"
              height="500px"
            ></img>
            <img
              src="../images/Round Cube2@3x.png"
              alt="some"
              className="blog-cube"
            ></img>
            <img
              src="../images/Rock_whywork_blue.png"
              className="blog-sphere"
              alt="some"
            ></img>
            <img
              src="../images/contentLine.png"
              className="blog-line"
              alt="some"
            ></img>
            <img
              src="../images/Ellipse 63.png"
              className="content-ellipse"
              alt="some"
            ></img>
          </div>
          <div className={classes.text}>
            <h1>{item?.attributes?.title}</h1>
            {/* <h2>Author : {data.author}.</h2> */}
            <h4>{item?.item?.attributes?.createdAt.split("T")[0]}.</h4>
            {/* <h4>{item?.['dc:creator'][0]}.</h4> */}
            {/* <p>{item?.attributes?.whole_bloc}.</p> */}
            <p
              dangerouslySetInnerHTML={{
                __html: item?.attributes?.whole_bloc.replace(/\n/g, "<br>"),
              }}
            />
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
}

export default BlogContent;
