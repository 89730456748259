import React from 'react'
import ListCalc from '../Container/ListingCalculator/ListCalc';
import ContactForm from '../Components/contactForm';
import Footer from '../Components/Footer';

function ListingCalculator() {
  return (
    <div>
        <ListCalc/>
        <ContactForm/>
        <Footer/>
        
    </div>
  )
}
export default ListingCalculator;
