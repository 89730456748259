import Footer from '../Components/Footer'
import {Privacy} from '../Container/privacy/Privacy'

function PrivacyPolicy() {
  return (
    <div className='margin-nav'>
     <Privacy/>
     <Footer/>
    </div>
  )
}

export default PrivacyPolicy
