import React from 'react'
import CodeDesc from '../Container/CodeDescription/CodeDesc';
import ContactForm from '../Components/contactForm';
import Footer from '../Components/Footer';

function ListingCalculator() {
  return (
    <div>
        <CodeDesc/>
        <ContactForm/>
        <Footer/>
        
    </div>
  )
}
export default ListingCalculator;
