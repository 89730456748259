export const FETCH_POST = 'FETCH_POST';
export const TOKEN = '5525500280a17324e9714411c6c98143c7cf1b21f500f2c203e752514bbe4b4bcfb2f0ee8444bf426e187bbb0e5a16d54fe2736cedfa2466ec7d4caa85acbd852320aedf425c87578edca75c13bc2531c2fd082e8a753045672133ee3622a57c5302bc87dd00d10723b206d575504a4d5f33261847d38cd4192403530c2266cf';
export const FILL_FORM="FILLFORM";
export const CLIENT_LIST="CLIENTLIST";
export const TESTIMONIALS="TESTIMONIALS";
export const SITE_COUNTERS="SITECOUNTERS";
export const FETCH_NEWS="FETCHNEWS";
export const FETCH_BLOGS="FETCHBLOGS";
export const FETCH_CATEGORY="FETCHCATEGORY";
export const FETCH_WORKS="FETCHWORKS";
export const FETCH_SINGLE_WORKS="FETCHSINGLEWORK";

// ############--------FBA-Details-------------##############

export const PRODUCT_DETAILS="PRODUCTDETAILS";
export const PRODUCT_PRICE="PRODUCTPRICE";
export const PRODUCT_PROGRAM="PRODUCTPROGRAM";
export const PRODUCT_FEES="PRODUCTFEES";

// ############--------Price-Listing-------------##############
export const LISTING_DETAILS="LISTINGDETAILS";
